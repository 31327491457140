import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=31d96939&scoped=true&"
import script from "./Main.vue?vue&type=script&lang=ts&"
export * from "./Main.vue?vue&type=script&lang=ts&"
import style0 from "./Main.vue?vue&type=style&index=0&id=31d96939&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d96939",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VContainer,VImg})
